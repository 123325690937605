import React from 'react';
import logo from '../assets/logo.png'; // Adjust the path to your logo image
import './SplashScreen.css'; // Import the CSS file for animations

const SplashScreen = () => {
    return (
        <div className="splash-screen">
            <img src={logo} alt="Logo" className="splash-logo" />
        </div>
    );
};

export default SplashScreen;
