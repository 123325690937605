// firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAjDWFXd1quZ2pwYAuyBex98S-KLvP1e5Q",
    authDomain: "fashion-virtual-try-on.firebaseapp.com",
    projectId: "fashion-virtual-try-on",
    storageBucket: "fashion-virtual-try-on.appspot.com",
    messagingSenderId: "950324386177",
    appId: "1:950324386177:web:384cff3b39eeaaad10f080",
    measurementId: "G-JPNZF6FDJB"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const analytics = getAnalytics(app);

export { storage };
