import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ImageUploader from './components/ImageUploader';
import { AiOutlineLoading3Quarters, AiOutlineDownload } from 'react-icons/ai';
import { CircularProgress, Typography, Box } from '@mui/material';
import { storage } from './firebase';
import { ref, getDownloadURL } from "firebase/storage";
import SplashScreen from './components/SplashScreen';
import './App.css';
import logo from './assets/logo.png';
import { createTheme, alpha, getContrastRatio } from '@mui/material/styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const violetBase = '#7F00FF';
const violetMain = alpha(violetBase, 0.7);

const theme = createTheme({
    palette: {
        violet: {
            main: violetMain,
            light: alpha(violetBase, 0.5),
            dark: alpha(violetBase, 0.9),
            contrastText: getContrastRatio(violetMain, '#fff') > 4.5 ? '#fff' : '#111',
        },
    },
});

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="determinate"
                {...props}
                size={80}
                thickness={4}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="white"
                    fontSize="1.5rem"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

const App = () => {
    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [outputImage, setOutputImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [img1URL, setImg1URL] = useState(null);
    const [img2URL, setImg2URL] = useState(null);
    const [folderPath, setFolderPath] = useState(null);
    const [img1Uploaded, setImg1Uploaded] = useState(false);
    const [img2Uploaded, setImg2Uploaded] = useState(false);
    const [showSplash, setShowSplash] = useState(true);
    const [showImageUploaders, setShowImageUploaders] = useState(true);
    /*const [showImageControlArea, setShowImageControlArea] = useState(false);*/
    const [apiError, setApiError] = useState(false);


    useEffect(() => {
        let timer = null;
        if (loading) {
            timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 1));
            }, 600);
        } else {
            setProgress(0);
        }
        return () => {
            clearInterval(timer);
        };
    }, [loading]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSplash(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    const validateImages = () => {
        const blobs = [image1?.blob, image2?.blob];
        for (const blob of blobs) {
            if (blob?.type.slice(0, 5) !== 'image') {
                alert('Only image files are allowed.');
                return false;
            }
            if (blob?.size > 10 * 1024 * 1024) {
                alert('Each image must be smaller than 10MB.');
                return false;
            }
        }
        return true;
    };

    const handleUpload = async () => {
        if (!validateImages()) return;

        setLoading(true);

        try {
            const token = process.env.REACT_APP_BACKEND_TOKEN;
            const apiUrl = process.env.REACT_APP_PROCESS_IMAGES_URL;

            await axios.post(apiUrl, {
                img1: img1URL,
                img2: img2URL,
                folder: folderPath,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-custom-token': token,
                }
            });

            const resultFilePath = `${folderPath}/result.png`;
            const resultRef = ref(storage, resultFilePath);
            const resultUrl = await getDownloadURL(resultRef);

            setOutputImage(resultUrl);
        } catch (error) {
            console.error('Error during API call:', error);
            setShowImageUploaders(true);
            setApiError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleStartOver = () => {
        setImage1(null);
        setImage2(null);
        setOutputImage(null);
        setLoading(false);
        setProgress(0);
        setImg1Uploaded(false);
        setImg2Uploaded(false);
        setShowImageUploaders(true);
    };

    const setViewportHeight = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    useEffect(() => {
        setViewportHeight();
        window.addEventListener('resize', setViewportHeight);
        return () => {
            window.removeEventListener('resize', setViewportHeight);
        };
    }, []);

    const handleDownload = () => {
        if (outputImage) {
            const link = document.createElement('a');
            link.href = outputImage;
            link.download = 'output.png';

            if (isMobileDevice()) {
                link.target = '_blank';
            }

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };


    return (
        <>
            {showSplash ? (
                <SplashScreen />
            ) : (
                <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center justify-between p-4 space-y-2">
                    <img src={logo} alt="Logo" className="w-30 h-14 mb-4" />
                    <div className="w-full max-w-2xl lg:max-w-[90%] flex-grow bg-gray-800 rounded-md flex justify-center items-center relative mt-2">
                        {loading ? (
                            // Show progress bar when loading
                            <CircularProgressWithLabel value={progress} />
                        ) : outputImage ? (
                            // Show output image when available
                            <div className="relative w-72 h-96">
                                <img src={outputImage} alt="Output" className="output-image w-full h-full rounded-md" />
                                <div className="absolute bottom-2 right-2 w-24 h-24 bg-white bg-opacity-60 rounded-full flex items-center justify-center">
                                    <div className="w-8 h-8 flex items-center justify-center">
                                        <span className="text-gray-800 font-bold text-xs">stylo</span>
                                    </div>
                                </div>
                                {/* <AiOutlineDownload className="absolute top-2 right-2 w-6 h-8 bg-gray-700 p-1 rounded cursor-pointer text-xl" onClick={handleDownload} /> */}
                                <br></br>
                                <button
                                    className="w-full h-12  py-2 bg-gradient-to-r from-purple-500 to-indigo-500 rounded-full text-white text-sm font-medium hover:opacity-80 disabled:opacity-50 flex justify-center items-center"
                                    onClick={handleStartOver}
                                >
                                    {loading ? <AiOutlineLoading3Quarters className="animate-spin mr-2" /> : 'Start Over'}
                                </button>
                            </div>

                        ) :
                            showImageUploaders ? (
                                <div className="w-full flex flex-col justify-center items-center space-y-4 p-4">
                                    <div className="w-full flex justify-center items-center space-x-4">
                                        <div className="w-1/2">
                                            <ImageUploader
                                                image={image1}
                                                setImage={setImage1}
                                                label="full-body"
                                                folderPath={folderPath}
                                                setFolderPath={setFolderPath}
                                                setImgURL={setImg1URL}
                                                imgUploaded={img1Uploaded}
                                                setImgUploaded={setImg1Uploaded}
                                                setApiError={setApiError}
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <ImageUploader
                                                image={image2}
                                                setImage={setImage2}
                                                label="garment"
                                                folderPath={folderPath}
                                                setFolderPath={setFolderPath}
                                                setImgURL={setImg2URL}
                                                imgUploaded={img2Uploaded}
                                                setImgUploaded={setImg2Uploaded}
                                                setApiError={setApiError}
                                            />
                                        </div>
                                    </div>
                                    {img1Uploaded && img2Uploaded && (
                                        <button
                                            className="mb-2 py-2 w-full h-12 bg-gradient-to-r from-purple-500 to-indigo-500 rounded-full text-white hover:opacity-80 disabled:opacity-50 flex justify-center items-center"
                                            onClick={() => {
                                                handleUpload();
                                                setShowImageUploaders(false);
                                                setApiError(false);
                                            }}
                                            disabled={loading || !img1Uploaded || !img2Uploaded}
                                        >
                                            {loading ? <AiOutlineLoading3Quarters className="animate-spin mr-2" /> : apiError ? 'Retry' : 'Try On'}
                                        </button>
                                    )}
                                    {apiError && (
                                        <p className="text-red-500 mb-4">An error has occurred. Please try again.</p>
                                    )}
                                </div>
                            ) : (
                                outputImage && (
                                    <button
                                        className="w-full py-2 bg-gradient-to-r from-purple-500 to-indigo-500 rounded-full text-white text-sm font-medium hover:opacity-80 disabled:opacity-50 flex justify-center items-center"
                                        onClick={handleStartOver}
                                    >
                                        {loading ? <AiOutlineLoading3Quarters className="animate-spin mr-2" /> : 'Start Over'}
                                    </button>
                                )
                            )}
                    </div>
                </div>
            )}
        </>
    );
};

export default App;
