import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AiFillDelete, AiOutlineLoading3Quarters, AiOutlineReload } from 'react-icons/ai';
import { GiClothes } from 'react-icons/gi';
import { FaPerson } from 'react-icons/fa6';
import Cropper from 'react-easy-crop';
import 'cropperjs/dist/cropper.css';
import './ImageUploader.css';

const ImageUploader = ({ image, setImage, label, folderPath, setFolderPath, setImgURL, imgUploaded, setImgUploaded, setApiError }) => {
    const [uploading, setUploading] = useState(false);
    const [cropping, setCropping] = useState(false);
    const [uploadError, setUploadError] = useState(false); // New state for upload error
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const cropperRef = useRef(null);

    useEffect(() => {
        if (image && !image.cropped) {
            setCropping(true);
        }
    }, [image]);

    const handleRemoveImage = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setImage(null);
        setImgUploaded(false);
        setApiError(false);
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const getCroppedImg = async (imageSrc, pixelCrop) => {
        const image = new Image();
        image.src = imageSrc;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            throw new Error('No 2d context');
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        ctx.drawImage(
            image,
            pixelCrop.x * scaleX,
            pixelCrop.y * scaleY,
            pixelCrop.width * scaleX,
            pixelCrop.height * scaleY,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        );

        // As a blob
        return new Promise((resolve) => {
            canvas.toBlob(file => {
                resolve(URL.createObjectURL(file));
            }, 'image/jpeg');
        });
    };

    const handleCrop = useCallback(async () => {
        try {
            setCropping(true);
            setUploading(true);
            const croppedImage = await getCroppedImg(
                image.preview,
                croppedAreaPixels,
            );
            setImage({ preview: croppedImage, blob: await fetch(croppedImage).then(res => res.blob()), cropped: true });
            await handleUpload(await fetch(croppedImage).then(res => res.blob()));
            setCropping(false);
            setUploading(false);
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels]);

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage({ preview: URL.createObjectURL(file), blob: file });
        }
    };

    const handleUpload = async (croppedBlob = null) => {
        try {
            let currentFolderPath = folderPath;
            if (!currentFolderPath) {
                const timestamp = new Date().toISOString().replace(/[-:.]/g, '').replace('T', '-').split('.')[0];
                currentFolderPath = `vton/${timestamp}`;
                setFolderPath(currentFolderPath);
            }

            const filename = `${label}.png`;
            const blobToUpload = croppedBlob || image.blob;
            const downloadUrl = await uploadImage(currentFolderPath, filename, blobToUpload);
            setImgURL(downloadUrl);
            setImgUploaded(true);
            setUploadError(false);
        } catch (error) {
            console.error('Error uploading image:', error);
            setUploadError(true);
        }
    };

    const uploadImage = async (folderPath, filename, file) => {
        try {
            const response = await fetch(process.env.REACT_APP_UPLOAD_IMAGE_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-custom-token': process.env.REACT_APP_BACKEND_TOKEN,
                },
                body: JSON.stringify({
                    filename: `${folderPath}/${filename}`,
                    filecontent: await blobToBase64(file),
                }),
            });
            const data = await response.json();
            if (data.download_url) {
                return data.download_url;
            } else {
                throw new Error('Failed to get download URL');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="relative w-full h-64 bg-gray-800 border-dashed border-4 border-gray-700 rounded-md flex items-center justify-center cursor-pointer hover:bg-gray-700">
            <input
                type="file"
                accept="image/*"
                className="hidden"
                id={`image-upload-${label}`}
                onChange={handleFileInputChange}
            />
            {!image && (
                <label htmlFor={`image-upload-${label}`} className="absolute inset-0 cursor-pointer flex items-center justify-center">
                    <div className="flex flex-col items-center justify-center text-gray-500 text-center">
                        {label === 'full-body' ? (
                            <FaPerson className="text-4xl" />
                        ) : (
                            <GiClothes className="text-4xl" />
                        )}
                        <p className="mt-2">
                            {label === 'full-body' ? (
                                <>
                                    + Full body image
                                </>
                            ) : (
                                <>
                                    + Garment image
                                </>
                            )}
                        </p>
                    </div>
                </label>
            )}
            <div className="w-full h-full" onClick={stopPropagation}>
                {image && (
                    <>
                        {cropping ? (
                            <div className="w-full h-full">
                                <Cropper
                                    ref={cropperRef}
                                    image={image.preview}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={3 / 4}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                    showGrid={true}
                                    onInitialized={() => {
                                        const cropper = cropperRef.current?.cropper;
                                        cropper?.setCropBoxData({
                                            left: 0,
                                            top: 0,
                                            width: cropper.getContainerData().width,
                                            height: cropper.getContainerData().height
                                        });
                                    }}
                                />
                                <button
                                    className="absolute top-64 w-40 h-16 justify-center left-1/2 transform -translate-x-1/2 bg-gradient-to-r from-purple-500 to-indigo-500 rounded-full text-white hover:opacity-80 flex items-center small-button"
                                    onClick={handleCrop}
                                >
                                    {uploading ? (
                                        <AiOutlineLoading3Quarters className="animate-spin mr-2" />
                                    ) : (
                                        'Crop & Upload'
                                    )}
                                </button>
                            </div>
                        ) : (
                            <>
                                <img src={image.preview} alt="Uploaded" className="w-full h-full object-contain rounded-md" />
                                <AiFillDelete
                                    className="absolute w-6 h-8 top-2 right-2 text-white-500 bg-gray-800 p-1 rounded cursor-pointer"
                                    onClick={handleRemoveImage}
                                />
                            </>
                        )}
                        {uploadError && (
                            <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex items-center text-red-500">
                                <p className="mr-2">An error has occurred. Retry</p>
                                <AiOutlineReload className="cursor-pointer" onClick={() => handleUpload()} />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ImageUploader;
